@import "../../styles/variables";

.textarea {
    max-height: 600px;
    min-height: 120px;
    padding: 12px 20px;
    width: 700px;
    margin: 10px 0;
    height: auto;
    font-size: 15px;
    font-weight: 300;
}

.textInput {
    padding: 12px 20px;
    width: 700px;
    margin: 10px 0;
    font-size: 15px;
}

.field {
    margin-bottom: 10px;
}

.checkboxInput {
    margin-left: 20px;
}
